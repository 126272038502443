<div class="navbar two-action no-hairline">
    <div class="navbar-inner d-flex align-items-center">
        <div class="left">
            <a routerLink="/" class="link icon-only">
                <i class="fa fa-long-arrow-left">
                </i>

            </a>
        </div>
        <div class="sliding custom-title">{{origin}} to {{destination}} Outstation Taxi</div>
    </div>
</div>

<div class="container">
    <div class="row">

        <div class="mt-4 col-md-12">
            <h1 style="font-size: 18px;">
                {{title}}
            </h1>

            <p>
                Book {{origin}} to {{destination}} one way cabs with {{settings.siteName}}, best online outstation taxi service provider in {{origin}}.

            </p>



            <hr class="bg-gray-300">

            <h4 class="mb-3">Book Drop Taxi from {{origin}} to {{destination}} </h4>
            <div class="hide-map">
                <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="5">
                    <ng-container *ngIf="showDirection">
                        <agm-direction [origin]="origin" [destination]="destination" (onResponse)="onResponses($event)">
                        </agm-direction>
                    </ng-container>
                </agm-map>
            </div>

            <ng-container *ngIf="showEstimation">
                <app-outstation></app-outstation>
            </ng-container>

            <p>
                {{settings.siteName}} provides One Way Car Rental from {{origin}} to {{destination}}, which includes the pick from anywhere in {{origin}} including city / {{origin}} Airport and drop off to {{destination}} City. We provide instant confirmation and flexibility
                to book 24x7.
            </p>

            <p>
                One Way Taxi from {{origin}} to {{destination}} is the best option for travelers who would like to travel one way only from {{origin}} to {{destination}}. Cab/Taxi would easily pick you up and drop you at your preferred location anywhere in {{destination}}.
                It is not a shared Cab. It will be a dedicated cab for you.
            </p>

            <p>
                {{origin}} to {{destination}} one way car rental packages are designed in such a way, so user could avail more benefits by just paying one way drop off charges. So why to wait more, hurry up, you are just few step away to grab this deal.
            </p>





        </div>


    </div>
</div>