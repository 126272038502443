import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CanonicalService } from 'src/app/canonical.service';
import { CARS } from 'src/app/cars';
import { OutstationService } from 'src/app/outstation.service';
import { SITE_SETTINGS } from 'src/app/settings';
import { TaxiService } from 'src/app/taxi.service';

@Component({
  selector: 'app-city-detail',
  templateUrl: './city-detail.component.html',
  styleUrls: ['./city-detail.component.css']
})
export class CityDetailComponent implements OnInit {

  origin;
  destination;
  latitude: number = 11.127123;
  longitude: number = 78.656891;
  zoom: number = 5;
  totalDistance;
  totalDuration;
  showDirection;
  cars;
  estimations = [];
  settings = SITE_SETTINGS;
  title;
  routeDetails;
  showEstimation = false;
  days;

  constructor(private outstationService: OutstationService, private activeRoute: ActivatedRoute, private canonicalService: CanonicalService, private titleService: Title, private metaTagService: Meta, private taxiService: TaxiService, private router: Router
    ) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();

    let routeUrl = this.activeRoute.snapshot.paramMap.get('url').split('-');
    this.origin = routeUrl[0].charAt(0).toUpperCase() + routeUrl[0].slice(1);
    this.destination = routeUrl[2].charAt(0).toUpperCase() + routeUrl[2].slice(1);

    (this.origin && this.destination) ? this.showDirection = true : this.showDirection = false;

    this.title = `Book ${this.origin} to ${this.destination} drop taxi stating from 14 Rs per km | ${this.settings.siteName}`;
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: `Book ${this.origin} to ${this.destination} drop taxi stating from 14 Rs per km. Choose from a range of ${this.origin} To ${this.destination} cabs - round trips, and one-way cabs` }
    );
  }

  back() { 
    this.router.navigateByUrl('/city');
  }

  onResponses(event) {
    this.routeDetails = {
      'tripType': 'One Way',
      'origin': this.origin,
      'destination': this.destination,
      'originCityName': this.origin,
      'destinationCityName': this.destination,
      'totalDistance': event.routes[0].legs[0].distance.text.replace(/[^0-9.-]+/g, ""),
      'totalDuration': event.routes[0].legs[0].duration.text,
      'distance': event.routes[0].legs[0].distance.text.replace(/[^0-9.-]+/g, "")
    }

    this.taxiService.tariff.subscribe(res => {
      if (res) {
        this.cars = res;
        this.getEstimation(this.routeDetails);
      }
    });

  }

  getEstimation(routes) {

    let distance;

    if (routes.tripType === 'One Way') {
      distance = (routes.distance <= 130) ? routes.distance = 130 : routes.distance;
    }

    if (routes.tripType === 'Round Trip') {
      let startDate = moment(moment(routes.pickupDate).format('DD/MM/YYYY'), "DD.MM.YYYY");
      let returnDate = moment(moment(routes.returnDate).format('DD/MM/YYYY'), "DD.MM.YYYY");
      this.days = returnDate.diff(startDate, 'days') + 1;
      distance = (routes.distance * 2 <= 250) ? routes.distance = this.days * 250 : (routes.distance * 2 > 250 * this.days) ? routes.distance = routes.distance * 2 : routes.distance = 250 * this.days;
    }
    this.cars.forEach(car => {
      car['tripEstimation'] = (routes.tripType === 'One Way') ? car.oneway * distance + car.onewayDriverFee : car.roundtrip * distance + car.roundTripDriverFee * this.days;
      car['driverBatta'] = (routes.tripType === 'One Way') ? car.onewayDriverFee : car.roundTripDriverFee * this.days;
      car['ratePerKM'] = (routes.tripType === 'One Way') ? car.oneway : car.roundtrip;
      car['distance'] = routes.distance;
      car['baseFare'] = (routes.tripType === 'One Way') ? car.oneway * distance : car.roundtrip * distance;
      car['tripType'] = routes.tripType;
    });

    console.log(this.cars);
  }

}
