<!-- Start header_area -->
<marquee behavior="" direction="">One way Drop taxi available all over Tamilnadu Pondicherry Karnataka & Kerela 
</marquee>

<header class="header_area header_v1 transparent_header">
    <div class="container">
        <div class="site_menu">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <div class="brand">
                        <a href="index.html" class="logo"><img src="./assets/logo.png" class="img-fluid"
                                alt="One Way Outstation Taxi Provider" /></a>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="primary_menu" id="menu">
                        <nav class="main_menu">
                            <ul>
                                <ul>
                                    <li class="menu-item "><a routerLink="/">Home</a>

                                    </li>

                                    <li class="menu-item "><a routerLink="/about">About Us</a>


                                    <li class="menu-item "><a routerLink="/city">Outstation Taxi Routes</a>



                                    <li class="menu-item "><a routerLink="/contact">Contact Us</a>


                                    <li class="menu-item"><a href="tel:{{settings.phoneNo}}"
                                            class="deneb_btn">{{settings.phoneNo}}</a></li>
                                </ul>
                            </ul>
                        </nav>

                    </div>
                </div>
            </div>
        </div>
        <div class="mobile_wrapper">
            <div class="mobile_header">
                <div class="row align-items-center">
                    <div class="col-10">
                        <div class="brand_logo">
                            <a href="index.html"><img src="./assets/logo.png" class="img-fluid"
                                    alt="One Way Outstation Taxi Provider" /></a>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="menu_button">
                            <div class="menu_icon">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sidenav_menu">
                <div class="close_icon">
                    <a href="#" class="close_btn"><i class="fas fa-times"></i></a>
                </div>
                <ul class="sidebar-menu">
                    <ul>
                        <li class="menu-item "><a routerLink="/">Home</a>

                        </li>

                        <li class="menu-item "><a routerLink="/about">About Us</a>


                        <li class="menu-item "><a routerLink="/city">Outstation Taxi Routes</a>

                        <li class="menu-item "><a routerLink="/tariff">Tariff</a>


                        <li class="menu-item "><a routerLink="/contact">Contact Us</a>


                        <li class="menu-item"><a style="padding-top: 5px;" href="tel:{{settings.phoneNo}}"
                                class="deneb_btn">{{settings.phoneNo}}</a></li>
                    </ul>
                </ul>
            </div>
        </div>
    </div>
</header>
<!-- End header_area -->

<!-- Start deneb_banner section -->
<section class="deneb_banner banner_v1">
    <div class="shape_v1">
        <img src="./assets/images/shape/shape_1.png" class="shape_1" alt="slider one">
    </div>
    <div class="container">
        <div class="hero_slider">
            <div class="signle_slider">
                <div class="row align-items-center">
                    <div class="col-lg-5">
                        <div class="banner_content wow fadeInLeft">
                            <h1 style="font-size: 24px;">Book <span>Drop Taxi</span> @ Just 14 Rs</h1>
                            <p>We are one of the best outstation drop Taxi providers in Tamil Nadu. We value your
                                money,
                                time and safety. Have a worry free and safe ride with our professional drivers and
                                experts in navigation</p>
                            <a style="
                            padding: 2px 30px;
                        " href="tel:{{settings.phoneNo}}" class="deneb_btn">(+91) {{settings.phoneNo}}

                            </a>
                        </div>
                    </div>
                    <div id="booking" class="col-lg-12 deneb_contact mt-4 contact_v2 mb-4">
                        <section class="deneb_cta">
                            <div class="container">
                                <app-outstation></app-outstation>

                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="deneb_service service_v1">
    <div class="shape_v2">
        <img src="./assets/images/shape/shape_7.png" class="shape_7" alt="">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section_title text-center">
                    <h2>Outstation Taxi Tariff</h2>

                </div>
            </div>
        </div>
        <div class="row">
            <table style="width:100%; position: relative;" colspan="2">
                <tbody>
                    <tr>
                        <th>Vehicle Type</th>
                        <th>One Way</th>
                        <th>Round Trip</th>
                        <th>Driver Allowance</th>
                    </tr>
                    <tr *ngFor="let car of cars">
                        <td>{{car.carType}}</td>
                        <td>Rs.{{car.oneway}}/KM</td>
                        <td>Rs.{{car.roundtrip}}/ KM</td>
                        <td>Rs.{{car.onewayDriverFee}}</td>
                    </tr>

                </tbody>
            </table>

        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-lg-12">
             

                <div class="section_title mt-5 mb-2">
                    <h3>ADDITIONAL CHARGES DETAILS

                    </h3>
                    <p>
                        Additional charges may be added to your trip fare if your driver has to wait for more than a
                        day.
                        These additional charges may be retained by your driver for 250km/day.
                    </p>

                    <div>
                        Toll fees,Inter-State Permit, GST charges (if any) are extra. <br>
                        Drop Trips-Minimum running must be 130kms per day.<br>

                        Round Trips-Minimum running must be 250kms per day.For Bengaluru it is minimum 300kms per
                        day.<br>

                        1 day means 1 calender day (from midnight 12 to Next Midnight 12)<br>
                        Cancellation Charges Rs.500 2hrs before scheduled departure <br>
                        For night driving between 10:00 pm to 6:00 am for Round Trip, additional Rs. 300 (per night)
                        <br>
                        For one way trip above 400 KM, additional Rs. 300<br>
                        Bangalore pickup round Trip par day minimum 300km<br>
                        Waiting charges Rs.150<br>
                        Any other state 250Km minimum<br>
                        For Pets additional Rs.500
                    </div>

                    <h3>Cancellation Policy
                    </h3>
                    <p>There are cancellation fees except in such cases where the Taxi has already arrived at the
                        customers location. In such cases, Rs.500 as a One-time cancellation fee has to be paid to
                        driver.</p>

                    <h3>Luggage Policy</h3>

                    <p>
                        We understand luggages are not only suitcases. They are of different shapes and sizes. Table
                        below is only indicative. Excess luggage charges, if any, will be at the discretion of driver.
                        Kindly note, taxis are passenger vehicles only.</p>

                </div>
            </div>
        </div>
    </div>

</section>
<!-- End deneb_banner section-->
<!-- Start deneb_service section -->
<section class="deneb_service service_v1">
    <div class="shape_v2">
        <img src="./assets/images/shape/shape_7.png" class="shape_7" alt="">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section_title text-center">
                    <h2>{{settings.siteName}}</h2>
                    <p>All the vehicles are checked regularly and maintained well. The drivers are well experienced,
                        properly trained, neatly groomed and polite towards the customers. .
                    </p>
                </div>
            </div>
        </div>
        <div class="row">

            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="grid_item wow fadeInUp">
                    <div class="icon">
                        <img src="./assets/images/icon_1.png" class="img-fluid" alt="">
                    </div>
                    <div class="info">
                        <h4><a href="/services/strategy_research">Reliable Outstation Taxi
                            </a></h4>
                        <p>Make your trips hassle-free by pre-booking our Outstation Taxis </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="grid_item wow fadeInUp">
                    <div class="icon">
                        <img src="./assets/images/icon_2.png" class="img-fluid" alt="">
                    </div>
                    <div class="info">
                        <h4><a href="/services/web_development">24 / 7 Outstation Taxi Support</a></h4>
                        <p>We Offer 24x7 Pickup Up And Drop Facility In Tamil Nadu To Facilitate The Customers Emergency
                            Needs.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="grid_item wow fadeInUp">
                    <div class="icon">
                        <img src="./assets/images/icon_3.png" class="img-fluid" alt="">
                    </div>
                    <div class="info">
                        <h4><a href="/services/web_solution">Top Rated Drivers</a></h4>
                        <p>All Our Driver trainer To Delivery The Best Experience.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End deneb_service section -->
<!-- Start deneb_about section -->
<section class="deneb_about  about_v1 ">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="deneb_img_box wow fadeInLeft">
                    <img src="./assets/images/about.png" class="img-fluid" alt="">
                </div>
            </div>
            <div class="col-lg-7">
                <div class="deneb_content_box wow fadeInRight">
                    <h2>Why You Hire Us?</h2>
                    <p>
                        {{settings.siteName}} services available for all over south states. You can save up to 40
                        percent taxi fare compare then others Presenting in most prominent cities in India.
                    </p>
                    <p>
                        Trusted one way Outstation Taxi for both Outstation - One way , Two way and Multiway Taxi
                        Service.
                    </p>
                    <a href="#booking" class="deneb_btn">Book Now</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End deneb_about section -->
<!-- Start deneb_achivement section -->
<section class="deneb_achivement section_padding">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12  wow fadeInLeft">
                <div class="section_title">
                    <h2>Over 500+ Happy Customers & Still Counting</h2>
                </div>
                <div class="deneb_content_box">
                    <p>
                        Booked a outstation taxi with {{settings.siteName}} from vellore to Chennai and had a safe and
                        comfortable journey. Hassle free booking, the fare was cheap and affordable compared to other
                        taxi services. Best outstation taxi service out there!
                    </p>

                </div>
            </div>
        </div>
    </div>
</section>

<section class="deneb_blog blog_v1">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section_title text-center">
                    <h2>Popular Chennai Outstation Drop Taxi Routes</h2>
                    <p>
                        You can book a cab in Chennai by calling us on {{settings.phoneNo}} if you need a Outstation
                        Taxi for
                        your day out in the city.
                    </p>
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-12">

                <div class="knsl-center knsl-title-frame knsl-scroll-animation knsl-mb-100">
                    <h2 class="knsl-mb-20">Popular One Way & Round Trip Outstation Taxi Routes </h2>
                    <p class="knsl-mb-30">
                        {{settings.siteName}} is an outstation taxi services specialist across Tamil Nadu. In addition
                        to one-way AC cab outstation taxi services we also provide round trips, packaged tours and all
                        sorts of
                        customization to suit your travel needs across all cities and towns in Tamil Nadu, Pondicherry
                        ,Bangalore & Kerala.
                    </p>
                </div>

            </div>
            <div class="col-lg-4">
                <div class="knsl-ath-card">
                    <div class="description">
                        <h4><i class="fal fa-map-marker-alt" aria-hidden="true"> </i> <b>THANJAVUR OUTSTATION
                                DROPTAXI</b>
                        </h4>
                        <img src="assets/brihadeeshwara-temple.jpeg" width="100%" alt="" srcset="">
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/tanjavur-to-bangalore-outstation-droptaxi"> Tanjavur to
                                Bangalore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/tanjavur-to-trichy-outstation-droptaxi"> Tanjavur
                                to Trichy</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/tanjavur-to-coimbatore-outstation-droptaxi"> Tanjavur to
                                Coimbatore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/tanjavur-to-pondicherry-outstation-droptaxi">
                                Tanjavur to Pondicherry</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/tanjavur-to-madurai-outstation-droptaxi">
                                Tanjavur to Madurai</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/tanjavur-to-cuddalore-outstation-droptaxi"> Tanjavur to
                                Cuddalore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/tanjavur-to-chennai-outstation-droptaxi"> Tanjavur to
                                Chennai</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/tanjavur-to-kumbakonam-outstation-droptaxi"> Tanjavur to
                                Kumbakonam</a></p>

                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="knsl-ath-card">
                    <div class="description">
                        <h4><i class="fal fa-map-marker-alt" aria-hidden="true"> </i> <b>KUMBAKONAM OUTSTATION
                                DROPTAXI</b>
                        </h4>
                        <img src="assets/KUMBAKONAM.jpg" width="100%" alt="" srcset="">
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/kumbakonam-to-bangalore-outstation-droptaxi"> Kumbakonam to
                                Bangalore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/kumbakonam-to-trichy-outstation-droptaxi"> Kumbakonam
                                to Trichy</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/kumbakonam-to-coimbatore-outstation-droptaxi"> Kumbakonam to
                                Coimbatore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/kumbakonam-to-pondicherry-outstation-droptaxi">
                                Kumbakonam to Pondicherry</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/kumbakonam-to-madurai-outstation-droptaxi">
                                Kumbakonam to Madurai</a></p>

                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/kumbakonam-to-chennai-outstation-droptaxi"> Kumbakonam
                                to
                                Chennai</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/kumbakonam-to-thanjavur-outstation-droptaxi">
                                Kumbakonam
                                to
                                Thanjavur</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/tanjavur-to-kanyakumari-outstation-droptaxi"> Kumbakonam to
                                Kanyakumari</a></p>

                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="knsl-ath-card">
                    <div class="description">
                        <h4><i class="fal fa-map-marker-alt" aria-hidden="true"> </i> <b>CHENNAI OUTSTATION DROPTAXI</b>
                        </h4>
                        <img src="assets/chennai.jpeg" width="100%" alt="" srcset="">

                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/chennai-to-bangalore-outstation-droptaxi"> Chennai to
                                Bangalore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/chennai-to-trichy-outstation-droptaxi"> Chennai
                                to Trichy</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/chennai-to-coimbatore-outstation-droptaxi"> Chennai to
                                Coimbatore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/chennai-to-pondicherry-outstation-droptaxi">
                                Chennai to Pondicherry</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/chennai-to-madurai-outstation-droptaxi">
                                Chennai to Madurai</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/chennai-to-cuddalore-outstation-droptaxi"> Chennai to
                                Cuddalore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/chennai-to-thanjavur-outstation-droptaxi"> Chennai to
                                Thanjavur</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/chennai-to-kumbakonam-outstation-droptaxi"> Chennai to
                                Kumbakonam</a></p>
                    </div>
                </div>
            </div>


            <div class="col-lg-4">
                <div class="knsl-ath-card">
                    <div class="description">
                        <h4><i class="fal fa-map-marker-alt" aria-hidden="true"> </i> <b>CUDDALORE OUTSTATION TRIP</b>
                        </h4>
                        <img src="/assets/dcvd_1523085767t.jpg" width="100%" alt="" srcset="">

                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/cuddalore-to-chennai-outstation-droptaxi"> Cuddalore to
                                Chennai</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/cuddalore-to-coimbatore-outstation-droptaxi"> Cuddalore to
                                Coimbatore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/cuddalore-to-trichy-outstation-droptaxi"> Cuddalore to
                                Trichy</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/cuddalore-to-tirunelveli-outstation-droptaxi"> Cuddalore to
                                Tirunelveli</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/cuddalore-to-kanyakumari-outstation-droptaxi"> Cuddalore to
                                Kanyakumari</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/cuddalore-to-velankanni-outstation-droptaxi"> Cuddalore to
                                Velankanni</a></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="knsl-ath-card">
                    <div class="description">
                        <h4><i class="fal fa-map-marker-alt" aria-hidden="true"> </i> <b>COIMBATORE OUTSTATION TRIP</b>
                        </h4>
                        <img src="assets/Isha-Yoga-Centre-Coimbatore-1.jpeg" width="100%" alt="" srcset="">

                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/coimbatore-to-chennai-outstation-droptaxi"> Coimbatore to
                                Chennai</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/coimbatore-to-trichy-outstation-droptaxi"> Coimbatore to
                                Trichy</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/coimbatore-to-bangalore-outstation-droptaxi"> Coimbatore to
                                Bangalore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/coimbatore-to-madurai-outstation-droptaxi"> Coimbatore to
                                Madurai</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/coimbatore-to-tirunelveli-outstation-droptaxi"> Coimbatore to
                                Tirunelveli</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/coimbatore-to-vellore-outstation-droptaxi"> Coimbatore to
                                Vellore</a></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="knsl-ath-card">
                    <div class="description">
                        <h4><i class="fal fa-map-marker-alt" aria-hidden="true"> </i> <b>TRICHY OUTSTATION TRIP</b></h4>
                        <img src="assets/cover-Places-To-Visit-In-Trichy_8th-June.jpeg" width="100%" alt="" srcset="">

                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/trichy-to-bangalore-outstation-droptaxi"> Trichy to
                                Bangalore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/trichy-to-chennai-outstation-droptaxi"> Trichy to
                                Chennai</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/trichy-to-coimbatore-outstation-droptaxi"> Trichy to
                                Coimbatore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/trichy-to-cuddalore-outstation-droptaxi"> Trichy to
                                Cuddalore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/trichy-to-salem-outstation-droptaxi"> Trichy to
                                Salem</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/trichy-to-pondicherry-outstation-droptaxi"> Trichy to
                                Pondicherry</a></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="knsl-ath-card">
                    <div class="description">
                        <h4><i class="fal fa-map-marker-alt" aria-hidden="true"> </i> <b>MADURAI OUTSTATION TRIP</b>
                        </h4>
                        <img src="https://images.outlookindia.com/public/uploads/articles/2020/8/18/Meenakshi_Amman_Temple,_Madurai.jpg"
                            width="100%" alt="" srcset="">

                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/madurai-to-bangalore-outstation-droptaxi">
                                Madurai to Bangalore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/madurai-to-chennai-outstation-droptaxi">
                                Madurai to Chennai</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/madurai-to-coimbatore-outstation-droptaxi">
                                Madurai to Coimbatore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/madurai-to-erode-outstation-droptaxi"> Madurai
                                to Erode</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/madurai-to-pondicherry-outstation-droptaxi">
                                Madurai to Pondicherry</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/madurai-to-kanyakumari-outstation-droptaxi">
                                Madurai to Kanyakumari</a></p>
                    </div>
                </div>
            </div>


            <div class="col-lg-4">
                <div class="knsl-ath-card">
                    <div class="description">
                        <h4><i class="fal fa-map-marker-alt" aria-hidden="true"> </i> <b>TIRUNELVELI OUTSTATION TRIP</b>
                        </h4>
                        <img src="https://www.transindiatravels.com/wp-content/uploads/nellaiappar-temple.jpg"
                            width="100%" alt="" srcset="">
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/tirunelveli-to-bangalore-outstation-droptaxi">
                                Tirunelveli to Bangalore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/tirunelveli-to-chennai-outstation-droptaxi">
                                Tirunelveli to Chennai</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/tirunelveli-to-coimbatore-outstation-droptaxi">
                                Tirunelveli to Coimbatore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/tirunelveli-to-salem-outstation-droptaxi">
                                Tirunelveli to Salem</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/tirunelveli-to-pondicherry-outstation-droptaxi"> Tirunelveli
                                to Pondicherry</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/tirunelveli-to-neyveli-outstation-droptaxi">
                                Tirunelveli to Neyveli</a></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="knsl-ath-card">
                    <div class="description">
                        <h4><i class="fal fa-map-marker-alt" aria-hidden="true"> </i> <b>SALEM OUTSTATION TRIP</b></h4>
                        <img src="https://intercityriders.com/assets/images/cities/salem.jpg" width="100%" alt=""
                            srcset="">
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/salem-to-bangalore-outstation-droptaxi"> Salem
                                to Bangalore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/salem-to-Chennai-outstation-droptaxi"> Salem to
                                Chennai</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/salem-to-Coimbatore-outstation-droptaxi"> Salem
                                to Coimbatore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/salem-to-bangalore-outstation-droptaxi"> Salem
                                to Cuddalore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/salem-to-bangalore-outstation-droptaxi">
                                Salem to Tirunelveli</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/salem-to-bangalore-outstation-droptaxi">
                                Salem to Pondicherry</a></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="knsl-ath-card">
                    <div class="description">
                        <h4><i class="fal fa-map-marker-alt" aria-hidden="true"> </i> <b>ERODE OUTSTATION TRIP</b></h4>
                        <img src="assets/erode.gif" width="100%" alt="" srcset="">
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/erode-to-bangalore-outstation-droptaxi"> Erode
                                to Bangalore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/erode-to-chennai-outstation-droptaxi"> Erode to
                                Chennai</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/erode-to-coimbatore-outstation-droptaxi"> Erode
                                to Coimbatore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/erode-to-tirunelveli-outstation-droptaxi">
                                Erode to Tirunelveli</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/erode-to-pondicherry-outstation-droptaxi">
                                Erode to Pondicherry</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/erode-to-kanyakumari-outstation-droptaxi">
                                Erode to Kanyakumari</a></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="knsl-ath-card">
                    <div class="description">
                        <h4><i class="fal fa-map-marker-alt" aria-hidden="true"> </i> <b>TUTICORIN OUTSTATION TRIP</b>
                        </h4>
                        <img src="assets/TUTICORIN.jpeg" width="100%" alt="" srcset="">
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/tuticorin-to-bangalore-outstation-droptaxi">
                                Tuticorin to Bangalore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/tuticorin-to-chennai-outstation-droptaxi">
                                Tuticorin to Chennai</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/tuticorin-to-coimbatore-outstation-droptaxi">
                                Tuticorin to Coimbatore</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/tuticorin-to-salem-outstation-droptaxi">
                                Tuticorin to Salem</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/tuticorin-to-pondicherry-outstation-droptaxi">
                                Tuticorin to Pondicherry</a></p>
                        <p><i class="fal fa-map-marker-alt" aria-hidden="true"></i> <a
                                routerLink="/city/tuticorin-to-trichy-outstation-droptaxi">
                                Tuticorin to Trichy</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="deneb_blog blog_v1">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section_title text-center">
                    <h2>Popular Chennai Outstation Drop Taxi Routes</h2>
                    <p>
                        You can book a cab in Chennai by calling us on {{settings.phoneNo}} if you need a Outstation
                        Taxi for
                        your day out in the city.
                    </p>
                </div>
            </div>
        </div>
        <div class="row">

            <div *ngFor="let place of places[0].routes" class="col-lg-4 col-md-6 col-sm-12">
                <div class="grid_item wow fadeInUp">
                    <article>

                        <div class="deneb_img">
                            <figure>
                                <a
                                    routerLink="/city/{{place.origin | lowercase}}-to-{{place.destination | lowercase}}-outstation-taxi"><img
                                        src="{{place.image}}" class="img-fluid" alt="Welcome to Jekyll!" /></a>
                            </figure>
                        </div>

                        <div class="deneb_info">
                            <time class="date">Chnneai One Way Drop Taxi</time>
                            <h3><a routerLink="/city/{{place.origin | lowercase}}-to-{{place.destination | lowercase}}-outstation-taxi"
                                    class="stretched-link">{{place.origin}} to
                                    {{place.destination}}</a></h3>
                            <p class="mb-2">
                                {{place.origin}} to {{place.destination}} fare is starting from Rs {{place.estimation}}
                                for Sedan and you have to pay at Rs 12 per km. Book {{place.origin}} to
                                {{place.destination}} Outstation Taxi at guaranteed lowest fare at {{settings.siteName}}
                                and
                                get
                                amazing deals on{{place.origin}} to {{place.destination}} One way taxi & round trip cab
                                booking.
                            </p>
                            <a routerLink="/city/{{place.origin | lowercase}}-to-{{place.destination | lowercase}}-outstation-taxi"
                                class="link_btn">Read More</a>
                        </div>
                    </article>
                </div>
            </div>

        </div>
    </div>
</section>


<section *ngFor="let route of  places" class="deneb_blog blog_v1">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section_title text-center">
                    <h2>Popular {{route.city}} One Way Outstation Taxi Routes</h2>
                </div>
            </div>
        </div>

        <owl-carousel-o [options]="customOptions">
            <ng-template *ngFor="let place of  route.routes" carouselSlide>
                <div class="grid_item wow fadeInUp">
                    <article>

                        <div class="deneb_img">
                            <figure>
                                <a
                                    routerLink="/city/{{place.origin | lowercase}}-to-{{place.destination | lowercase}}-outstation-taxi"><img
                                        src="{{place.image}}" class="img-fluid" alt="Welcome to Jekyll!" /></a>
                            </figure>
                        </div>

                        <div class="deneb_info">
                            <time class="date">Vellore One Way Outstation Taxi</time>
                            <h3><a routerLink="/city/{{place.origin | lowercase}}-to-{{place.destination | lowercase}}-outstation-taxi"
                                    class="stretched-link">{{place.origin}} to
                                    {{place.destination}}</a></h3>
                            <p class="mb-2">
                                {{place.origin}} to {{place.destination}} fare is starting from Rs {{place.estimation}}
                                for Sedan and you have to pay at Rs 11 per km. Book {{place.origin}} to
                                {{place.destination}} Outstation Taxi at guaranteed lowest fare at {{settings.siteName}}
                                and
                                get
                                amazing deals on{{place.origin}} to {{place.destination}} One way taxi & round trip cab
                                booking.
                            </p>
                            <a routerLink="/city/{{place.origin | lowercase}}-to-{{place.destination | lowercase}}-outstation-taxi"
                                class="link_btn">Read More</a>
                        </div>
                    </article>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</section>

<!-- Start deneb_cta section -->
<section class="deneb_cta">
    <div class="container">
        <div class="cta_wrapper">
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <div class="cta_content">
                        <h3>Have Any Project in Mind ?</h3>
                        <p>Curabitur libero eros, efficitur sit amet sodales tincidunt, aliquet et leo sed ut nibh
                            feugiat, auctor enim quis.</p>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="button_box">
                        <a href="contact" class="deneb_btn">Hire Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End deneb_cta section -->
<!-- Start footer -->
<footer class="deneb_footer">
    <div class="widget_wrapper" style="background-image: url(/assets/images/footer_bg.png);">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="widget widegt_about mb-md-4 mb-lg-0">
                        <div class="widget_title">
                            <img src="./assets/logo.png" class="img-fluid" alt="One Way Outstation Taxi Provider" />
                        </div>
                        <p>We are the excellent, economical, prompt, friendly and elite class Outstation Taxi in
                            Chennai. We
                            know you deserve the best so we offer not just a Outstation Taxi but a Super Taxi in
                            Vellore.</p>
                        <ul class="social">

                            <li><a href="https://www.facebook.com/tortoiztheme" target="__blank"><i
                                        class="fab fa-facebook-f"></i></a></li>


                            <li><a href="https://twitter.com/tortoizthemes" target="__blank"><i
                                        class="fab fa-twitter"></i></a></li>


                            <li><a href="https://instagram.com/" target="__blank"><i class="fab fa-instagram"></i></a>
                            </li>

                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="widget widget_link">
                        <div class="widget_title">
                            <h4>Essencial Links</h4>
                        </div>
                        <ul class="list-unstyled footer-link">
                            <li>
                                <a href="/city/chennai-to-madurai-outstation-taxi">Chennai to Madurai cabs</a>
                            </li>
                            <li>
                                <a href="/city/chennai-to-pondicherry-outstation-taxi">Chennai to Pondicherry cabs
                                </a>
                            </li>
                            <li>
                                <a href="/city/chennai-to-bangalore-outstation-taxi">Chennai to Bangalore cabs
                                </a>
                            </li>
                            <li>
                                <a href="/city/chennai-to-vellore-outstation-taxi">Chennai to Vellore cabs
                                </a>
                            </li>
                            <li>
                                <a href="/city/chennai-to-thanjavur-outstation-taxi">Chennai to Thanjavur cabs
                                </a>
                            </li>
                            <li>
                                <a href="/city/chennai-to-coimbatore-outstation-taxi">Chennai to Coimbatore cabs
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="widget widget_contact">
                        <div class="widget_title">
                            <h4>Contact Us</h4>
                        </div>
                        <div class="contact_info">
                            <div class="single_info">
                                <div class="icon">
                                    <i class="fas fa-phone-alt"></i>
                                </div>
                                <div class="info">
                                    <p><a href="tel:{{settings.phoneNo}}">{{settings.phoneNo}}</a></p>
                                    <p><a href="https://wa.me/{{settings.whatsapp}}">{{settings.whatsapp}}</a></p>
                                </div>
                            </div>
                            <div class="single_info">
                                <div class="icon">
                                    <i class="fas fa-envelope"></i>
                                </div>
                                <div class="info">
                                    <p><a href="mailto:{{settings.enquiryEmail}}">{{settings.enquiryEmail}}</a></p>
                                </div>
                            </div>
                            <div class="single_info">
                                <div class="icon">
                                    <i class="fas fa-map-marker-alt"></i>
                                </div>
                                <div class="info">
                                    <p>
                                        <span>Chennai & Coimbatore</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright_area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="copyright_text">
                        <p>Copyright &copy; 2024 <span>{{settings.siteName}}</span>. All rights reserved.</p>
                        <p>Developed By<span><a href="https://www.calvincareinfotech.com">Calvin Care</a></span></p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>