export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyCClQmipEXf9UsfZbgUpuN-h3GetlmyUvo",
    authDomain: "diamonddroptaxi.firebaseapp.com",
    databaseURL: "https://diamonddroptaxi-default-rtdb.firebaseio.com",
    projectId: "diamonddroptaxi",
    storageBucket: "diamonddroptaxi.appspot.com",
    messagingSenderId: "782931559803",
    appId: "1:782931559803:web:14bf1d9a4348ad8191a9de"
  }
};
