<div class="container">
    <section class="muse-section pb-4 pb-md-5 mt-4">
        <div class="row align-items-center aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            <div class="col-lg-8">
                <h1 style="
                font-size: 22px;" class="mb-4">
                    <img (click)="back()" style="
                    width: 30px;
                    margin-right: 15px;
                " src="assets/left-arrow.png" alt="" srcset=""> One Way Cab Routes</h1>
                <p class="big lh-lg text-black-600">Outstation one way and Round trip cab routes accross Tamilnadi, Bangalore, Pondicherry and Kerala.</p>
            </div>
        </div>

        <hr class="bg-gray-300">
        <div class="row pt-4">
            <mat-accordion>
                <mat-expansion-panel *ngFor="let city of places">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            One Way Outstation Taxi in {{city}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container *ngFor="let subCity of places">
                        <h6 class="city" *ngIf="city !== subCity">
                            <a class="text-black-600 caption font-weight-semibold" (click)="selectedCity(city, subCity )" routerLink="/city/{{city | lowercase}}-to-{{subCity | lowercase}}-outstation-taxi"> {{city}} to {{subCity}} One Way Cabs</a>
                        </h6>
                    </ng-container>

                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </section>
</div>