<div class="side-menu" id="sidebar-menu">
    <div class="close-btn"><span class="material-icons">close</span></div>

    <ul>
        <li>
            <img src="assets/logo-black.png" alt="">

        </li>
        <li>
            <a class="" routerLink="/">
                Home
            </a>
        </li>
        <li>
            <a class="" routerLink="/about">
                About
            </a>
        </li>
        <li>
            <a class="" routerLink="/city">
                Outstation Routes
            </a>
        </li>
        <li>
            <a class="" routerLink="/tariff">
                Tariff
            </a>
        </li>
        <li>
            <a class="" routerLink="/contact">
                Contact
            </a>
        </li>

    </ul>
</div>