import { MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CARS } from 'src/app/cars';
import { PLACES, popular_cities, popular_city } from 'src/app/places';
import { SITE_SETTINGS } from 'src/app/settings';
import { TaxiService } from 'src/app/taxi.service';
declare var $;



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {


  @ViewChild('origin', { read: ElementRef }) originElementRef: ElementRef;
  @ViewChild('destination', { read: ElementRef }) destinationElementRef: ElementRef;
  // @ViewChild('stepper') stepper: MatStepper;

  title = 'mytaxi';
  places = PLACES;
  cars;
  popularCities = popular_cities;
  settings = SITE_SETTINGS;
  cities = popular_city;
  latitude: number = 11.127123;
  longitude: number = 78.656891;
  zoom: number = 5;
  origin;
  destination;
  showDirection = false;
  pickupPlace;
  dropPlace;
  totalDistance;
  totalDuration;
  requestRide = false;
  rideDetails;
  originCityName;
  destinationCityName;
  routeDetails;
  showSpinner = false;
  firstFormGroup: FormGroup;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['Previous', 'Next'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      }
    },
    nav: false
  }
  submitted = false;

  constructor(private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private router: Router, private taxiService: TaxiService, private _formBuilder: FormBuilder, private http: HttpClient,
  ) { }

  ngOnInit() {

    this.taxiService.tariff.subscribe(res => {
      if(res) {
        this.cars = res;
      }
    });

    this.firstFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      phone: ['', [
        Validators.required,
        Validators.pattern(/^[0]?[6789]\d{9}$/)
      ]]
    });

    this.mapsAPILoader.load().then(() => {
      const origin = this.originElementRef?.nativeElement;
      const destination = this.destinationElementRef?.nativeElement;

      this.addMapEventListener(origin, 'origin');
      this.addMapEventListener(destination, 'destination');
    });
  }

  get contactValidation() { return this.firstFormGroup.controls; }


  ngAfterViewInit() {
    $.getScript("./assets/js/custom.js");
  }

  onResponses(event) {
    this.showSpinner = false;
    this.routeDetails = {
      'origin': this.pickupPlace,
      'destination': this.dropPlace,
      'originCityName': this.originCityName,
      'destinationCityName': this.destinationCityName,
      'totalDistance': event.routes[0].legs[0].distance.text.replace(/[^0-9.-]+/g, ""),
      'totalDuration': event.routes[0].legs[0].duration.text
    }
    console.log(this.routeDetails);
  }

  addMapEventListener(input, type) {
    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.setComponentRestrictions({
      country: ["in"],
    });
    autocomplete.addListener("place_changed", () => {

      this.ngZone.run(() => {
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        (type === 'origin') ? this.origin = place.formatted_address : this.destination = place.formatted_address;
        (type === 'origin') ? this.originCityName = place.name : this.destinationCityName = place.name;

        if (this.origin && this.destination) {
          this.showSpinner = true;
          this.showDirection = true;
          this.pickupPlace = this.origin;
          this.dropPlace = this.destination;
        }

        this.zoom = 12;
      });
    });
  }

  book() {

    this.submitted = true;

    if (!this.origin || !this.destination) {
      alert('Please enter origin and destination');
    }

    if (this.firstFormGroup.invalid) {
      return
    }

    this.rideDetails = {
      ...this.firstFormGroup.value,
      ...this.routeDetails
    }

    this.http.post(this.settings.quickEnquiry, { ...this.rideDetails, ...this.settings }).subscribe(res => console.log(res));

    if (this.routeDetails) {
      this.taxiService.routeDetails.next(this.rideDetails);
      this.router.navigateByUrl('/select-cabs', { skipLocationChange: true });
    }
  }
}
