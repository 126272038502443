<div class="container">

    <section class="muse-section">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="border-bottom border-gray-200 px-4 px-md-5 py-4 ">
                    <h5 class="mb-0 ">
                        <img routerLink="/" style="
                        width: 30px;
                        margin-right: 15px;
                    " src="assets/left-arrow.png" alt="" srcset=""> Our Outstation Tariff </h5>
                </div>

                <div class="my-3">

                    <ng-container *ngFor="let car of cars ">
                        <div class="d-flex align-items-center ">
                            <div class="d-flex align-items-center ">
                                <span>
        <span class="avatar avatar-circle avatar-border mr-3 ">
        <img class="avatar-img " src="{{car.image}} " alt="Avatars ">
        <span class="avatar-status avatar-success ">&nbsp;</span>
                                </span>
                                </span>
                                <div>
                                    <h6 class="font-weight-normal ">{{car.carType}}
                                    </h6>
                                    <small class="d-block text-gray-600 ">
                                        ONE WAY : <span class="badge badge-primary fs-11 py-1 px-2 ml-1"> ₹{{car.oneway}} / km </span> <br>
                                        ROUND TRIP : <span class="badge badge-primary fs-11 py-1 px-2 ml-1"> ₹{{car.roundtrip}} / km</span> <br>
                                    </small>
                                </div>
                            </div>
                            <div class="ml-auto dropdown ">
                                <a routerLink="/" class="btn btn-sm btn-warning text-uppercase">Book Now</a>
                            </div>
                        </div>
                        <hr class="bg-gray-200 my-4 ">

                    </ng-container>



                </div>
            </div>
        </div>
    </section>
</div>