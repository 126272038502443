import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG, LazyMapsAPILoaderConfigLiteral } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OutstationComponent } from './components/outstation/outstation.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { BookingContactComponent } from './components/booking-contact/booking-contact.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BookingSuccessComponent } from './components/booking-success/booking-success.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CityDetailComponent } from './pages/city-detail/city-detail.component';
import { RouteDetailComponent } from './pages/route-detail/route-detail.component';
import { MatListModule } from '@angular/material/list';
import { AboutComponent } from './pages/about/about.component';
import { TariffComponent } from './pages/tariff/tariff.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AllRoutesComponent } from './pages/all-routes/all-routes.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { OnewayComponent } from './components/oneway/oneway.component';
import { RentalComponent } from './components/rental/rental.component';
import { AirportComponent } from './components/airport/airport.component';
import { RoundComponent } from './components/roundtrip/roundtrip.component';
import { map } from 'rxjs/operators';


export function agmConfigFactory(http: HttpClient, config: LazyMapsAPILoaderConfigLiteral) {
  return () => {
    if (typeof window !== 'undefined') {
      const id = window.location.pathname.replace(/\//g, "");
      return http.get<any>(`https://us-central1-calvincare-emailservice.cloudfunctions.net/mapkey`).pipe(
        map(response => {
          config.apiKey = response['mapkey'];
          console.log(response['mapkey']);
          return response;
        })
      ).toPromise();
    } else {
      return Promise.resolve(null);
    }
  }
};




@NgModule({
  declarations: [
    AppComponent,
    OutstationComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    BookingContactComponent,
    BookingSuccessComponent,
    CityDetailComponent,
    RouteDetailComponent,
    AboutComponent,
    TariffComponent,
    ContactComponent,
    AllRoutesComponent,
    OnewayComponent,
    RentalComponent,
    AirportComponent,
    RoundComponent,
    OutstationComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCX1XBz1biy59vO_BIOf6RSQ29SFrSD784',
      libraries: ["places"]
    }),
    AgmDirectionModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatListModule,
    CarouselModule,
    NgxMaterialTimepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatStepperModule,
    MatFormFieldModule,
    MatSelectModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: agmConfigFactory,
      deps: [HttpClient, LAZY_MAPS_API_CONFIG],
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
